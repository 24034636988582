// import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Parallax } from "react-scroll-parallax"

// import Img from "gatsby-image"
const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "content1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "content2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "content5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "content4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)



  return (
    <div className="gallery" id="our-mentality">
      <h4>Our Mentality</h4>

      <div className="gallery__imagetext-wrapper">
        <Parallax y={["-80px", "50px"]} tagOuter="h2">
          You can’t become who you want to be by <span onClick={() => scrollTo("#stay-with-us")}>staying</span> who you are.
        </Parallax>
        <Parallax
          className="gallery__image gallery__image2"
          y={["0px", "-20px"]}
          tagOuter="div"
        >
          <Img fluid={data.image2.childImageSharp.fluid} alt="Hero" />
        </Parallax>
      </div>
      <Parallax
        className="gallery__image gallery__image1"
        y={["-20px", "40px"]}
        tagOuter="div"
      >
        <Img fluid={data.image1.childImageSharp.fluid} alt="Hero" />
      </Parallax>
      <Parallax
        className="gallery__image gallery__image3"
        y={["-60px", "80px"]}
        tagOuter="div"
      >
        <Img fluid={data.image3.childImageSharp.fluid} alt="Hero" />
      </Parallax>
      <Parallax
        className="gallery__image gallery__image4"
        y={["-60px", "90px"]}
        tagOuter="div"
      >
        <Img fluid={data.image4.childImageSharp.fluid} alt="Hero" />
      </Parallax>
     
    </div>
  )
}

export default Gallery
